import React, { useEffect, useRef } from "react";
import getBasicPluginContext from "paella-basic-plugins";
import { Paella, utils, PlayerState } from "paella-core";
import config from "config";
import { createLiveCameraManifest } from "utils/paella-manifests";
import { isDomainAllowed } from "utils/urls";

export async function customLoadVideoManifestFunction(
  title: string,
  streamSrc: string,
) {
  console.log("Using custom loadVideoManifest function");
  function getManifest() {
    console.log(`Using custom getManifest function`);

    if (isDomainAllowed(streamSrc, config.ALLOWED_DOMAINS)) {
      const result = createLiveCameraManifest(title, streamSrc, true);
      return result;
    }
    throw Error("Invalid stream source");
  }
  const manifest = getManifest();
  return JSON.parse(manifest);
}

export async function customtGetVideoIdFunction() {
  console.log("Using CUSTOM getVideoId function");
  return "/";
}

interface IPlayerProps {
  title: string;
  streamSrc: string;
}

export default function PermanentEmbedPlayer({
  title,
  streamSrc,
}: IPlayerProps) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // https://webcast.web.cern.ch/event/i991843?app=livehd&camera&slides

  useEffect(() => {
    const loadPaella = async () => {
      console.debug("Initializing Paella Player plugins context...");

      const initParams = {
        // Initialization callbacks
        configUrl: "/config/config.json",
        configResourcesUrl: "/images/",
        getVideoId: customtGetVideoIdFunction, // get the video identifier
        loadVideoManifest: () =>
          customLoadVideoManifestFunction(title, streamSrc), // get the manifest file content
        customPluginContext: [getBasicPluginContext()],
      };
      let player: any;
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) {
          return;
        }
        console.log("Initializing Paella Player plugins context... OK");
        player = new Paella(videoRef.current, initParams);
        console.log("Initializing Paella Player... OK");
      } else {
        console.log("Player already initialized. Skipping.");
        player = playerRef.current;
      }

      try {
        await player.loadManifest();
        console.log("Loading video manifest... OK");
        await utils.loadStyle("/config/style.css");
        console.debug("Loading styles from Paella Core API... OK");
        playerRef.current = player;
      } catch (error) {
        console.log(error);
      }
    };
    loadPaella();
    return () => {
      if (playerRef.current) {
        console.log("Unmount -> Setting player to null");
        const player = playerRef.current as any;
        if (player.state === PlayerState.LOADED) {
          player.unload();
        }
      }
    };
  }, [streamSrc, title]);

  if (!videoRef) {
    return <div>Loading player...</div>;
  }

  return (
    <div>
      <div
        data-testid="player-container"
        ref={videoRef}
        className="player-container"
        style={{
          // minHeight: "600px",
          width: "100%",
          marginTop: "60px",
          height: "100vh",
        }}
      />
    </div>
  );
}
