import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Segment,
  Dimmer,
  Loader,
  Header,
  Icon,
  Label,
} from "semantic-ui-react";
import PermanentWebcastPlayer from "../../PermanentWebcastsPage/components/PermanentWebcastPlayer/PermanentWebcastPlayer";
import ShareWebcastSection from "components/ShareWebcastSection/ShareWebcastSection";
import { usePermanentWebcast } from "hooks/webcasts/use-webcasts";
import { IWebcastCategory } from "types/webcast";

interface IProps {
  selectedCategory: IWebcastCategory;
}

interface IParams {
  webcastId: string;
}

export default function PermanentWebcastDetails({ selectedCategory }: IProps) {
  const { webcastId } = useParams<IParams>();
  const { data, isError, isLoading } = usePermanentWebcast(webcastId);

  if (isLoading) {
    return (
      <Segment basic textAlign="center">
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </Segment>
    );
  }

  if (isError) {
    return (
      <Segment basic textAlign="center">
        Unable to fetch the webcast details
      </Segment>
    );
  }

  if (data && data.result && !data.success) {
    return (
      <Segment basic textAlign="center">
        You cannot access the permanent stream.
      </Segment>
    );
  }

  if (data && data.result && data.success) {
    console.log(data.result);
    return (
      <div>
        <Header as="h3">
          <Icon name="video" />
          <Header.Content>
            {data.result.title} <Label>{selectedCategory.name}</Label>
          </Header.Content>
        </Header>
        <p>
          <Link to={`/permanent-webcasts/${selectedCategory.id}`}>
            <Icon name="arrow left" /> Back to category
          </Link>
        </p>
        <PermanentWebcastPlayer webcast={data.result} />

        <ShareWebcastSection
          title={data.result.title}
          embedCode={data.result.stream.embed_code}
          inverted={false}
        />
      </div>
    );
  }
  return null;
}
