import React from "react";
import { Grid, Loader, Segment } from "semantic-ui-react";

export default function WebcastLoadingContent() {
  return (
    <Grid.Row>
      <Grid.Column>
        <Segment inverted textAlign="center">
          <Loader data-testid="loader" active inline="centered">
            Loading event...
          </Loader>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
}
