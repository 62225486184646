import { useQuery } from "react-query";
import { AxiosError } from "axios";
import apiClient from "api/api-headers";
import { ICdsRecordListApiResponse } from "types/cds-record";

export const fetchCdsRecords = async () => {
  const { data } = await apiClient.get<ICdsRecordListApiResponse>(
    `webcasts/cds-records`,
  );
  return data;
};

export function useCdsRecords(options = {}) {
  return useQuery<ICdsRecordListApiResponse, AxiosError>(
    "cds-records",
    () => fetchCdsRecords(),
    {
      ...options,
    },
  );
}
