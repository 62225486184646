import React, { useEffect, useRef } from "react";
import getBasicPluginContext from "paella-basic-plugins";
import { Paella, utils, PlayerState } from "paella-core";
import getUserTrackingPluginsContext from "paella-user-tracking";
import { Container } from "semantic-ui-react";
import ViewSelector from "components/ViewSelector/ViewSelector";
import config from "config";
import getCernCustomPluginsContext from "plugins";
import { IStreamType, IWebcast } from "types/webcast";
import {
  createLiveCameraManifest,
  createLiveCameraSlidesManifest,
  createLiveSlidesManifest,
} from "utils/paella-manifests";

export async function customLoadVideoManifestFunction(
  cameraUrl: string | undefined,
  slidesUrl: string | undefined,
  streamType: IStreamType,
  webcastType: string,
  indicoId: string,
  webcastStatus: string,
) {
  console.log("Using custom loadVideoManifest function");
  function getManifest() {
    let usedType = "";
    const usedTitle = "Webcast";
    let usedCameraUrl = "";
    let usedSlidesUrl = "";

    console.log(
      `Using custom getManifest function: ${streamType} ${webcastType}`,
    );
    if (
      cameraUrl &&
      (streamType === "camera" || streamType === "dual") &&
      (webcastType === "Camera" || webcastType === "Camera & Slides")
    ) {
      usedType = "camera";
      usedCameraUrl = cameraUrl;
    }
    if (
      slidesUrl &&
      (streamType === "slides" || streamType === "dual") &&
      (webcastType === "Slides" || webcastType === "Camera & Slides")
    ) {
      usedType = "slides";
      usedSlidesUrl = slidesUrl;
    }
    if (
      cameraUrl &&
      slidesUrl &&
      streamType === "dual" &&
      webcastType === "Camera & Slides"
    ) {
      usedType = "camera_slides";
      usedCameraUrl = cameraUrl;
      usedSlidesUrl = slidesUrl;
    }
    console.log(`Indico ID: ${indicoId}`);
    const manifests: any = {
      camera_slides: () =>
        createLiveCameraSlidesManifest(
          usedTitle,
          usedCameraUrl,
          usedSlidesUrl,
          webcastStatus === "live" && !usedCameraUrl.includes("/vod/"),
        ),
      camera: () =>
        createLiveCameraManifest(
          usedTitle,
          usedCameraUrl,
          webcastStatus === "live" && !usedCameraUrl.includes("/vod/"),
        ),
      slides: () =>
        createLiveSlidesManifest(
          usedTitle,
          usedSlidesUrl,
          webcastStatus === "live" && !usedSlidesUrl.includes("/vod/"),
        ),
    };
    const manifestFunc = manifests[usedType];
    const result = manifestFunc();
    return result;
  }
  const manifest = getManifest();
  console.debug(manifest);
  return JSON.parse(manifest);
}

export async function customtGetVideoIdFunction() {
  console.log("Using CUSTOM getVideoId function");
  return "/";
}

interface IPlayerProps {
  webcast: IWebcast;
  streamType: IStreamType;
  showSelector?: boolean;
  isEmbed?: boolean;
  withCdn?: boolean;
}

function getStreamUrl(
  withCdn: boolean,
  streamSrc: string | undefined,
  originServer: string | undefined,
) {
  if (streamSrc && withCdn) {
    let cdnBaseUrl = "cern2.vo.llnwd.net/e20";
    if (originServer === "wowza21.cern.ch") {
      cdnBaseUrl = "cern2.vo.llnwd.net/e21";
    }
    // Remove from the url: https://wowza.cern.ch/ and replace it with the CDN base URL
    return streamSrc.replace("wowza.cern.ch", cdnBaseUrl);
  }
  return streamSrc;
}

export default function Player({
  streamType,
  webcast,
  showSelector,
  isEmbed,
  withCdn = false,
}: Readonly<IPlayerProps>) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const [displayFluid, setDisplayFluid] = React.useState(false);
  console.log(`IsEmbed: ${isEmbed}`);
  // Change the width to fluid when the user clicks on the full screen button
  const handleDisplayFluid = () => {
    setDisplayFluid(!displayFluid);
    const player = playerRef.current as any;
    if (player) {
      console.log(`Player state: ${player.state}`);
      if (player.state === PlayerState.LOADED) {
        console.log("Resizing player...");
        player.resize();
      }
    }
  };

  // https://webcast.web.cern.ch/event/i991843?app=livehd&camera&slides

  useEffect(() => {
    const loadPaella = async () => {
      console.debug("Initializing Paella Player plugins context...");
      let configPath = "/config/config.json";

      if (config.ENVIRONMENT === "DEV" || config.ENVIRONMENT === "QA") {
        configPath = "/config/config-qa.json";
      }

      const initParams = {
        // Initialization callbacks
        configUrl: configPath,
        configResourcesUrl: "/images/",
        getVideoId: customtGetVideoIdFunction, // get the video identifier
        loadVideoManifest: () =>
          customLoadVideoManifestFunction(
            getStreamUrl(
              withCdn,
              webcast.stream.camera_src,
              webcast.origin_server,
            ),
            getStreamUrl(
              withCdn,
              webcast.stream.slides_src,
              webcast.origin_server,
            ),
            streamType,
            webcast.stream.type,
            webcast.indico_id,
            webcast.type.toLowerCase(),
          ), // get the manifest file content
        customPluginContext: [
          getCernCustomPluginsContext(),
          getBasicPluginContext(),
          getUserTrackingPluginsContext(),
        ],
      };
      let player: any;
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) {
          return;
        }
        console.log("Initializing Paella Player plugins context... OK");
        player = new Paella(videoRef.current, initParams);
        console.log("Initializing Paella Player... OK");
      } else {
        console.log("Player already initialized. Skipping.");
        player = playerRef.current;
      }

      try {
        await player.loadManifest();
        console.log("Loading video manifest... OK");
        await utils.loadStyle("/config/style.css");
        console.debug("Loading styles from Paella Core API... OK");
        playerRef.current = player;
      } catch (error) {
        console.log(error);
      }
    };
    loadPaella();
    return () => {
      if (playerRef.current) {
        console.log("Unmount -> Setting player to null");
        const player = playerRef.current as any;
        if (player.state === PlayerState.LOADED) {
          player.destroy();
        }
      }
    };
  }, [
    webcast.stream.camera_src,
    webcast.stream.slides_src,
    streamType,
    webcast.stream.type,
    webcast.indico_id,
    webcast.type,
    withCdn,
    webcast.origin_server,
  ]);

  if (!videoRef) {
    return <div>Loading player...</div>;
  }

  if (isEmbed) {
    return (
      <div>
        <Container style={{ marginBottom: 20, marginTop: 20 }}>
          {showSelector && (
            <ViewSelector
              webcast={webcast}
              streamType={streamType}
              handleDisplayFluid={handleDisplayFluid}
              displayFluid={displayFluid}
            />
          )}
        </Container>
        <div
          data-testid="player-container"
          ref={videoRef}
          className="player-container"
          style={{
            width: "100%",
            marginTop: "60px",
            height: "100vh",
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <Container style={{ marginBottom: 20, marginTop: 20 }}>
        {showSelector && (
          <ViewSelector
            webcast={webcast}
            streamType={streamType}
            handleDisplayFluid={handleDisplayFluid}
            displayFluid={displayFluid}
          />
        )}
      </Container>
      <Container
        fluid={displayFluid}
        style={{ marginTop: showSelector ? "" : 60 }}
      >
        <div
          data-testid="player-container"
          ref={videoRef}
          className="player-container"
          style={{
            minHeight: "600px",
            paddingBottom: displayFluid ? "56.25%" : 0,
          }}
        />
      </Container>
    </div>
  );
}

Player.defaultProps = {
  showSelector: true,
  isEmbed: false,
};
