import React from "react";
import { AxiosError } from "axios";
import { Icon, Message, Segment } from "semantic-ui-react";
import buildWebcastLiteLink from "utils/webcast-lite-link-generator";

interface Props {
  error: AxiosError | null;
  indicoId: string;
}

export default function WebcastRestrictionsError({
  error,
  indicoId,
}: Readonly<Props>) {
  return (
    <Segment inverted textAlign="center">
      <Message error>
        <Message.Header>
          <Icon name="warning sign" />
          Unable to fetch the selected webcast restrictions (
          {error?.response?.status})
        </Message.Header>
        <Message.Content>
          {error?.response?.status && error.response?.status >= 500 && (
            <div>
              <p>
                It seems there is a problem with the API and we are unable to
                fetch the event.
              </p>
              <p>
                Please, try the following fallback website link to the event:{" "}
                <a
                  href={buildWebcastLiteLink(indicoId)}
                  title="Event on webcast Lite"
                >
                  Webcast Lite event {indicoId}
                </a>
              </p>
            </div>
          )}
        </Message.Content>
      </Message>
    </Segment>
  );
}
