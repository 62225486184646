import React from "react";
import { DateTime } from "luxon";
import { Icon } from "semantic-ui-react";

interface IProps {
  startDate: string;
  startTime: string;
  endDate: string | undefined;
  endTime: string | undefined;
  timezone: string | null;
  showCalendarIcon?: boolean;
}

export default function EventDateText({
  startDate,
  startTime,
  endDate,
  endTime,
  timezone = null,
  showCalendarIcon = true,
}: IProps) {
  const formatDateTime = (date: string, time: string) =>
    DateTime.fromISO(`${date}T${time}`).toFormat("cccc dd LLL yyyy, HH:mm");

  if (!endDate || !endTime) {
    return (
      <span>
        {showCalendarIcon && <Icon name="calendar" />}
        {formatDateTime(startDate, startTime)} ({timezone})
      </span>
    );
  }

  const startDateTime = formatDateTime(startDate, startTime);
  const endDateTime = formatDateTime(endDate, endTime);

  if (startDate === endDate) {
    return (
      <span>
        {showCalendarIcon && <Icon name="calendar" />} {startDateTime} &rarr;{" "}
        {DateTime.fromISO(`${endDate}T${endTime}`).toFormat("HH:mm")} (
        {timezone})
      </span>
    );
  }

  return (
    <span>
      {showCalendarIcon && <Icon name="calendar" />} {startDateTime} &rarr;{" "}
      {endDateTime} ({timezone})
    </span>
  );
}

EventDateText.defaultProps = {
  showCalendarIcon: true,
};
