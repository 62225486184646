import React from "react";
import { useParams } from "react-router-dom";
import ProtectedEmbed from "./components/ProtecedEmbed/ProtectedEmbed";
import PublicEmbed from "./components/PublicEmbed/PublicEmbed";
import TopMenu from "components/TopMenu/TopMenu";
import { useIsProtectedWebcast } from "hooks/webcasts/use-webcasts";

interface IParams {
  indicoId: string;
}

export default function EmbedPage() {
  const { indicoId } = useParams<IParams>();

  const {
    data: isProtectedData,
    error,
    isFetching,
  } = useIsProtectedWebcast(`i${indicoId}`);

  return (
    <>
      <TopMenu />
      {error && <div>Error getting webcast restrictions.</div>}
      {isFetching && <div>Checking webcast restrictions...</div>}
      {isProtectedData &&
        isProtectedData.result &&
        isProtectedData.result.is_restricted && (
          <ProtectedEmbed indicoId={indicoId} streamType="dual" />
        )}
      {isProtectedData &&
        isProtectedData.result &&
        !isProtectedData.result.is_restricted && (
          <PublicEmbed indicoId={indicoId} streamType="dual" />
        )}
    </>
  );
}
