import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "semantic-ui-css-offline/semantic.min.css";
import "./index.css";
import setupInterceptors from "api/axios-interceptors";
import { MediaContextProvider } from "app-media";
import config from "config";
import Main from "Main";

if (process.env.NODE_ENV === "production") {
  console.debug = () => {};
  // eslint-disable-next-line global-require
  const Sentry = require("@sentry/browser");
  Sentry.init({ dsn: config.SENTRY_DSN, environment: config.SENTRY_ENV });
}

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line global-require
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

setupInterceptors();

ReactDOM.render(
  <React.StrictMode>
    <MediaContextProvider>
      <Main />
    </MediaContextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
