import React from "react";
import { useParams } from "react-router-dom";
import { Dimmer, Grid, Loader, Segment } from "semantic-ui-react";
import TopMenu from "components/TopMenu/TopMenu";
import { useIsProtectedWebcast } from "hooks/webcasts/use-webcasts";
import ResponsiveContainer from "pages/ResponsiveContainer";
import ProtectedWebcast from "pages/WebcastPage/components/ProtectedWebcast/ProtectedWebcast";
import PublicWebcast from "pages/WebcastPage/components/PublicWebcast/PublicWebcast";
import WebcastRestrictionsError from "pages/WebcastPage/components/WebcastRestrictionsError/WebcastRestrictionsError";

interface IParams {
  indicoId: string;
}

export default function WebcastCameraPage() {
  const { indicoId } = useParams<IParams>();

  const {
    data: isProtectedData,
    error,
    isError,
    isLoading,
  } = useIsProtectedWebcast(`i${indicoId}`);
  return (
    <ResponsiveContainer>
      <TopMenu />
      <Segment basic>
        <Grid.Row>
          <Grid.Column>
            {isError && (
              <WebcastRestrictionsError error={error} indicoId={indicoId} />
            )}
            {isLoading && (
              <Segment basic>
                <Dimmer active>
                  <Loader>Checking webcast restrictions...</Loader>
                </Dimmer>
              </Segment>
            )}
            {isProtectedData &&
              isProtectedData.result &&
              isProtectedData.result.is_restricted && (
                <ProtectedWebcast indicoId={indicoId} streamType="camera" />
              )}
            {isProtectedData &&
              isProtectedData.result &&
              !isProtectedData.result.is_restricted && (
                <PublicWebcast indicoId={indicoId} streamType="camera" />
              )}
          </Grid.Column>
        </Grid.Row>
      </Segment>
    </ResponsiveContainer>
  );
}
