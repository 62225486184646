import React from "react";
import { AxiosError } from "axios";
import { Grid, Icon, Message, Segment } from "semantic-ui-react";
import buildWebcastLiteLink from "utils/webcast-lite-link-generator";

interface Props {
  error: AxiosError | null;
  indicoId: string;
}

export default function WebcastErrorContent({
  error,
  indicoId,
}: Readonly<Props>) {
  return (
    <Grid.Row>
      <Grid.Column>
        <Segment inverted textAlign="center">
          <Message error>
            <Message.Header>
              <Icon name="warning sign" />
              Unable to fetch the selected webcast ({error?.response?.status})
            </Message.Header>
            <Message.Content>
              {error && error.response?.status === 401 && (
                <p>You need to log in to access this webcast</p>
              )}
              {error && error.response?.status === 403 && (
                <p>You don&apos;t have permission to access this webcast</p>
              )}
              {error?.response?.status && error.response?.status >= 500 && (
                <div>
                  <p>
                    It seems there is a problem with the API and we are unable
                    to fetch the event.
                  </p>
                  <p>
                    Please, try the following fallback website link to the
                    event:{" "}
                    <a
                      href={buildWebcastLiteLink(indicoId)}
                      title="Event on webcast Lite"
                    >
                      Webcast Lite event {indicoId}
                    </a>
                  </p>
                </div>
              )}
            </Message.Content>
          </Message>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
}
