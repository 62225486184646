// @ts-nocheck
import React from "react";
import { Segment, List, Grid, Icon, Header } from "semantic-ui-react";
import EventDateText from "components/EventDateText/EventDateText";

interface IParams {
  webcast: IWebcast;
  horizontal?: boolean;
}

export default function WebcastInfoSection({ webcast, horizontal }: IParams) {
  let LIcon = List.Icon;
  if (horizontal) {
    LIcon = List.Icon;
  }
  return (
    <Segment inverted style={{ marginBottom: 20, marginTop: 20 }}>
      <Header as="h3" inverted dividing>
        <Icon name="info circle" />
        <Header.Content>Event details</Header.Content>
      </Header>
      <Grid stackable>
        <Grid.Column>
          <List
            inverted
            horizontal={horizontal}
            relaxed
            style={{ marginBottom: 20 }}
          >
            <List.Item>
              <LIcon
                name="calendar"
                size="large"
                verticalAlign="middle"
                className={horizontal ? "imaged" : ""}
              />
              <List.Content>
                <List.Header>Date</List.Header>
                <EventDateText
                  startDate={webcast.startDate.day}
                  startTime={webcast.startDate.time}
                  timezone={webcast.startDate.tz}
                  endDate={webcast.endDate.day}
                  endTime={webcast.endDate.time}
                  showCalendarIcon={false}
                />
              </List.Content>
            </List.Item>
            <List.Item>
              <LIcon
                name="location arrow"
                size="large"
                verticalAlign="middle"
                className={horizontal ? "imaged" : ""}
              />
              <List.Content>
                <List.Header>Location</List.Header>
                {webcast.room_name ? webcast.room_name : "-"}
              </List.Content>
            </List.Item>
          </List>
          <List
            inverted
            horizontal={horizontal}
            relaxed
            style={{ display: "block" }}
          >
            <List.Item>
              <LIcon
                name="bullhorn"
                size="large"
                verticalAlign="middle"
                className={horizontal ? "imaged" : ""}
              />
              <List.Content>
                <List.Header>Speaker(s)</List.Header>
                {webcast.speakers ? webcast.speakers : "-"}
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

WebcastInfoSection.defaultProps = {
  horizontal: false,
};
