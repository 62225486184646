import React from "react";
import Latex from "react-latex-next";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Container,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
  Image,
  List,
  MessageList,
  MessageItem,
} from "semantic-ui-react";
import AbstractSection from "../AbstractSection/AbstractSection";
import Player from "../Player/Player";
import WebcastInfoSection from "../WebcastInfoSection/WebcastInfoSection";
import { Media } from "app-media";
import ShareWebcastSection from "components/ShareWebcastSection/ShareWebcastSection";
import { IStreamType, IWebcast } from "types/webcast";

interface Props {
  webcast: IWebcast;
  code?: string;
  streamType: IStreamType;
  withCdn?: boolean;
}

interface NotStartedProps {
  webcastType: string;
  day: string;
  time: string;
  tz: string;
}

export function PrivateWebcastMessage() {
  return (
    <Message color="yellow">
      <span aria-label="Warning icon">⚠️ </span>
      <strong>This is a private view of the event.</strong>
    </Message>
  );
}

export function WebcastHeader({
  webcast,
  code,
}: Readonly<{
  webcast: IWebcast;
  code: string | undefined;
}>) {
  return (
    <Grid.Column>
      {code && <PrivateWebcastMessage />}
      <Header inverted as="h2" dividing>
        <Icon name="video" />
        <Header.Content>
          <Latex>{webcast.title}</Latex>
          {webcast.speakers && (
            <Header.Subheader>by {webcast.speakers}</Header.Subheader>
          )}
        </Header.Content>
      </Header>
    </Grid.Column>
  );
}

export function WebcastNotLiveContent({
  webcastType,
  day,
  time,
  tz,
}: Readonly<NotStartedProps>) {
  // Build the date and time of the event
  const eventDateTime = DateTime.fromISO(`${day}T${time}`);
  // const finished = nowPlus2Hours > eventDateTime;
  const finished = webcastType === "recent" || webcastType === "archived";

  let message = "";
  let text = `If the event has been recorded, it will be available shortly on CDS.`;
  if (finished) {
    message = "This event has already finished";
  } else {
    message = "This event has not started yet";
    text = `This event will start on ${eventDateTime.toFormat(
      "dd-MM-yyyy",
    )} at ${eventDateTime.toFormat(
      "HH:mm",
    )} (${tz}). You will need to reload the page.`;
  }

  return (
    <Container>
      <Segment inverted>
        <Message info size="large">
          <Message.Header>
            <Icon name="info" /> {message}
          </Message.Header>
          <p>{text}</p>
        </Message>
      </Segment>
    </Container>
  );
}

export default function WebcastContent({
  webcast,
  code,
  streamType,
  withCdn = false,
}: Readonly<Props>) {
  return (
    <>
      <Container>
        <Grid columns={1}>
          <Grid.Row>
            <WebcastHeader webcast={webcast} code={code} />
          </Grid.Row>
        </Grid>
      </Container>
      <Container>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              {webcast.extra_html !== "" && webcast.extra_html !== null && (
                <Message info>
                  <Icon name="info" />{" "}
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: webcast.extra_html,
                    }}
                  />
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Grid.Row>
        <Grid.Column>
          {webcast.type.toLowerCase() === "live" ||
          (webcast.type.toLowerCase() === "recent" && webcast.has_dvr) ||
          (webcast.type.toLowerCase() === "upcoming" && code) ? (
            <div>
              <Player
                streamType={streamType}
                webcast={webcast}
                withCdn={withCdn}
              />
            </div>
          ) : (
            <WebcastNotLiveContent
              day={webcast.startDate.day}
              time={webcast.startDate.time}
              tz={webcast.startDate.tz}
              webcastType={webcast.type.toLowerCase()}
            />
          )}
        </Grid.Column>
      </Grid.Row>

      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={12}>
              <Media lessThan="tablet">
                <WebcastInfoSection webcast={webcast} />
              </Media>
              <Media greaterThanOrEqual="tablet">
                <WebcastInfoSection webcast={webcast} horizontal />
              </Media>
              <AbstractSection webcast={webcast} inverted />
              <ShareWebcastSection
                title={webcast.title}
                embedCode={webcast.embed_code}
                inverted
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Message style={{ marginTop: 20, marginBottom: 20 }} color="blue">
                <Message.Header>
                  <Icon name="warning" /> Issues during the playback?
                </Message.Header>
                <MessageList>
                  <MessageItem>
                    <Link
                      to={`/event/i${webcast.indico_id}/cdn`}
                      title={`Event ${webcast.title} using CDN`}
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      Use this URL instead to watch the event using our CDN.
                    </Link>
                  </MessageItem>
                  <MessageItem>
                    Still having problems?{" "}
                    <a
                      href="https://cern.service-now.com/service-portal?id=sc_cat_item&name=issue-live-events-website&se=webcast"
                      title="CERN Live Events website report form"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      Contact us using this form.
                    </a>
                  </MessageItem>
                </MessageList>
              </Message>
              <List inverted>
                <List.Item>
                  <List.Content>
                    <List.Header>Event page</List.Header>
                    <a href={webcast.indico_link} title="Event page on Indico">
                      <Image
                        src="/images-client/indico_white.png"
                        alt="Indico Logo"
                        size="small"
                        className="indicoImageLink"
                      />
                    </a>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
}

WebcastContent.defaultProps = {
  code: undefined,
};
