import React, { useEffect, useState } from "react";
import { useWebcast } from "hooks/webcasts/use-webcasts";
import Player from "pages/WebcastPage/components/Player/Player";
import { IStreamType, IWebcast } from "types/webcast";

interface Props {
  indicoId: string;
  streamType: IStreamType;
}

export default function ProtectedEmbed({ indicoId, streamType }: Props) {
  const [webcast, setWebcast] = useState<IWebcast | undefined>(undefined);

  const { data: webcastData, error, isFetching } = useWebcast(`i${indicoId}`);

  useEffect(() => {
    // Set the webcast after fetching
    if (webcastData && webcastData.result) {
      setWebcast(webcastData.result);
    }
  }, [webcastData]);

  useEffect(() => {
    document.body.style.backgroundColor = "#060606";
    if (webcast) {
      document.title = `${webcast.title} - CERN Live Events Website (Webcast)`;
    }
  }, [webcast]);

  return (
    <div data-testid="public-webcast">
      {error && <div>Unable to display the webcast</div>}
      {isFetching && <div>Loading webcast</div>}
      {webcast &&
      (webcast.type.toLowerCase() === "live" ||
        webcast.type.toLowerCase() === "recent") ? (
        <div>
          <Player streamType={streamType} webcast={webcast} isEmbed />
        </div>
      ) : (
        <div>Webcast is not live</div>
      )}
    </div>
  );
}
