import AuthService from "../services/auth-service";
import apiClient from "./api-headers";

const setupInterceptors = () => {
  apiClient.interceptors.request.use(
    async (config) => {
      // Do something before request is sent
      const newConfig = config;
      const token = AuthService.getToken();
      if (AuthService.isLoggedIn()) {
        const cb = () => {
          if (token && newConfig.headers) {
            newConfig.headers.Accept = "application/json";
            newConfig.headers.Authorization = `Bearer ${token}`;
          }
          return Promise.resolve(newConfig);
        };
        return AuthService.updateToken(cb);
      }
      return Promise.resolve(newConfig);
    },
    (error) => {
      // Do something with request error
      console.log(error);
      return Promise.reject(error);
    },
  );

  apiClient.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      const newConfig = config;
      const token = AuthService.getToken();
      if (token && newConfig.headers) {
        newConfig.headers.Accept = "application/json";
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
      return newConfig;
    },
    (error) => {
      // Do something with request error
      console.log(error);
      return Promise.reject(error);
    },
  );

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        console.log(error);
      }
      return Promise.reject(error);
    },
  );
};

export default setupInterceptors;
