import React, { useState } from "react";
import {
  Segment,
  Accordion,
  Icon,
  Button,
  Header,
  Form,
} from "semantic-ui-react";

interface IParams {
  title: string;
  embedCode: string;
  inverted?: boolean;
}

export default function ShareWebcastSection({
  title,
  embedCode,
  inverted = false,
}: IParams) {
  const [activeShareIndex, setActiveShareIndex] = useState(-1);

  const handleActiveShare = () => {
    setActiveShareIndex(activeShareIndex === 0 ? -1 : 0);
  };

  const openSocialShareUrl = (linkUrl: string) => {
    const height = window.innerHeight / 2;
    const width = window.innerWidth / 2;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    window.open(
      linkUrl,
      "",
      `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600,top=${top},left=${left}`,
    );
  };

  const buildFacebookShareLink = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location}&t=${title}`;
    openSocialShareUrl(facebookUrl);
  };

  const buildTwitterShareLink = () => {
    const twitterUrl = `https://twitter.com/share?url=${window.location}&via=cern&text=${title}`;
    openSocialShareUrl(twitterUrl);
  };

  return (
    <Segment inverted={inverted}>
      <Accordion fluid inverted={inverted}>
        <Accordion.Title
          active={activeShareIndex === 0}
          index={0}
          onClick={handleActiveShare}
        >
          <Icon name="share alternate" />
          Share
        </Accordion.Title>
        <Accordion.Content active={activeShareIndex === 0}>
          <Button color="facebook" onClick={buildFacebookShareLink}>
            <Icon name="facebook" /> Facebook
          </Button>
          <Button color="twitter" onClick={buildTwitterShareLink}>
            <Icon name="twitter" /> Twitter
          </Button>

          <Header as="h5" inverted={inverted} dividing>
            <Icon name="code" />
            <Header.Content>Embed</Header.Content>
          </Header>
          <Form>
            <Form.TextArea value={embedCode} />
          </Form>
        </Accordion.Content>
      </Accordion>
    </Segment>
  );
}

ShareWebcastSection.defaultProps = {
  inverted: false,
};
