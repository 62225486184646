/* eslint-disable react/no-danger */
import React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import { IWebcast } from "types/webcast";

interface IParams {
  webcast: IWebcast;
  inverted?: boolean;
}

export default function AbstractSection({
  webcast,
  inverted = false,
}: IParams) {
  const { abstract, indico_link, title } = webcast;
  function createMarkup() {
    const htmlAbstract = abstract;
    return { __html: htmlAbstract };
  }

  return (
    <Segment inverted>
      <Header as="h3" inverted={inverted} dividing>
        <Icon name="align center" />
        <Header.Content>Abstract</Header.Content>
      </Header>

      <div className="webcast-abstract" style={{ marginTop: 20 }}>
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
      <div className="webcast-abstract" style={{ marginTop: 20 }}>
        <p>
          You can visit the{" "}
          <a href={indico_link} title={`${title} on Indico`}>
            Indico page
          </a>{" "}
          for more information about this event.
        </p>
      </div>
    </Segment>
  );
}

AbstractSection.defaultProps = {
  inverted: false,
};
