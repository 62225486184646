import React, { useEffect, useRef } from "react";
import getBasicPluginContext from "paella-basic-plugins";
import { Paella, utils, PlayerState } from "paella-core";
import { Container } from "semantic-ui-react";
import config from "config";
import { IWebcast } from "types/webcast";
import { createLiveCameraManifest } from "utils/paella-manifests";
import { isDomainAllowed } from "utils/urls";

export async function customLoadVideoManifestFunction(
  cameraUrl: string | undefined,
) {
  console.log("Using custom loadVideoManifest function");
  console.log(`Camera URL ${cameraUrl}`);
  function getManifest() {
    let usedType = "";
    const usedTitle = "Webcast";
    let usedCameraUrl = "";

    console.log("Using custom getManifest function");
    if (cameraUrl) {
      if (isDomainAllowed(cameraUrl, config.ALLOWED_DOMAINS)) {
        usedType = "camera";
        usedCameraUrl = cameraUrl;
      }
    }
    console.log(usedType);
    const manifests: any = {
      camera: () => createLiveCameraManifest(usedTitle, usedCameraUrl, true),
    };
    const manifestFunc = manifests[usedType];
    const result = manifestFunc();
    return result;
  }
  const manifest = getManifest();
  return JSON.parse(manifest);
}

export async function customtGetVideoIdFunction() {
  console.log("Using CUSTOM getVideoId function");
  return "/";
}

interface IPlayerProps {
  webcast: IWebcast;
  // streamType: IStreamType;
  showSelector?: boolean;
}

export default function PermanentWebcastPlayer({
  // streamType,
  webcast,
  showSelector,
}: IPlayerProps) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // https://webcast.web.cern.ch/event/i991843?app=livehd&camera&slides

  useEffect(() => {
    const loadPaella = async () => {
      console.debug("Initializing Paella Player plugins context...");

      const initParams = {
        // Initialization callbacks
        configUrl: "/config/config.json",
        configResourcesUrl: "/images/",
        getVideoId: customtGetVideoIdFunction, // get the video identifier
        loadVideoManifest: () =>
          customLoadVideoManifestFunction(webcast.stream.camera_src), // get the manifest file content
        customPluginContext: [getBasicPluginContext()],
      };
      let player: any;
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) {
          return;
        }
        console.log("Initializing Paella Player plugins context... OK");
        player = new Paella(videoRef.current, initParams);
        console.log("Initializing Paella Player... OK");
      } else {
        console.log("Player already initialized. Skipping.");
        player = playerRef.current;
      }

      try {
        await player.loadManifest();
        console.log("Loading video manifest... OK");
        await utils.loadStyle("/config/style.css");
        console.log("Loading styles from Paella Core API... OK");
        playerRef.current = player;
      } catch (error) {
        console.log(error);
      }
    };
    loadPaella();
    return () => {
      if (playerRef.current) {
        console.log("Unmount -> Setting player to null");
        const player = playerRef.current as any;
        if (player.state === PlayerState.LOADED) {
          player.unload();
        }
      }
    };
  }, [webcast.stream.camera_src]);

  if (!videoRef) {
    return <div>Loading player...</div>;
  }

  return (
    <div>
      <Container style={{ marginTop: showSelector ? "" : 60 }}>
        <div
          data-testid="player-container"
          ref={videoRef}
          className="player-container"
          style={{
            minHeight: "600px",
          }}
        />
      </Container>
    </div>
  );
}

PermanentWebcastPlayer.defaultProps = {
  showSelector: true,
};
