import React, { useEffect } from "react";
import App from "./App";
import AuthService from "services/auth-service";

export default function Main() {
  const [initialized, setInitialized] = React.useState(false);

  useEffect(() => {
    const onLoadCallback = () => {
      console.log("Keycloak initialized");
      setInitialized(true);
    };
    console.log("Initializing Keycloak...");
    AuthService.initKeycloak(onLoadCallback);
  }, []);

  return <App initialized={initialized} />;
}
