import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Segment, Grid, Container } from "semantic-ui-react";
import TopMenu from "components/TopMenu/TopMenu";
import { usePublicWebcast } from "hooks/webcasts/use-webcasts";
import ResponsiveContainer from "pages/ResponsiveContainer";
import WebcastContent from "pages/WebcastPage/components/WebcastContent/WebcastContent";
import WebcastErrorContent from "pages/WebcastPage/components/WebcastErrorContent/WebcastErrorContent";
import WebcastLoadingContent from "pages/WebcastPage/components/WebcastLoadingContent/WebcastLoadingContent";
import { IWebcast } from "types/webcast";

interface IParams {
  indicoId: string;
  code?: string;
}

export default function PreviewPage() {
  const { indicoId, code } = useParams<IParams>();
  const [webcast, setWebcast] = useState<IWebcast | undefined>(undefined);

  const {
    data: webcastData,
    isFetching,
    error,
  } = usePublicWebcast(`p${code}-${indicoId}`);

  useEffect(() => {
    if (webcastData?.result) {
      setWebcast(webcastData.result);
    }
  }, [webcastData]);

  useEffect(() => {
    document.body.style.backgroundColor = "#060606";
    if (webcast) {
      document.title = `${webcast.title} - CERN Live Events Website (Webcast)`;
    }
  }, [webcast]);

  return (
    <ResponsiveContainer>
      <TopMenu />
      <Segment basic>
        <Container>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column>
                {error && (
                  <WebcastErrorContent error={error} indicoId={indicoId} />
                )}
                {isFetching && <WebcastLoadingContent />}
                {webcast && (
                  <WebcastContent
                    webcast={webcast}
                    code={code}
                    streamType="dual"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </ResponsiveContainer>
  );
}
