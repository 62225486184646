import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dimmer, Grid, Loader, Segment } from "semantic-ui-react";
import TopMenu from "components/TopMenu/TopMenu";
import { useIsProtectedWebcast } from "hooks/webcasts/use-webcasts";
import ResponsiveContainer from "pages/ResponsiveContainer";
import ProtectedWebcast from "pages/WebcastPage/components/ProtectedWebcast/ProtectedWebcast";
import PublicWebcast from "pages/WebcastPage/components/PublicWebcast/PublicWebcast";
import WebcastRestrictionsError from "pages/WebcastPage/components/WebcastRestrictionsError/WebcastRestrictionsError";

interface IParams {
  indicoId: string;
}

export default function WebcastCdnPage() {
  const { indicoId } = useParams<IParams>();

  const {
    data: isProtectedData,
    error,
    isLoading,
    isError,
  } = useIsProtectedWebcast(`i${indicoId}`);

  useEffect(() => {
    document.body.style.backgroundColor = "#060606";
  }, []);

  return (
    <ResponsiveContainer>
      <TopMenu />
      <Segment basic padded>
        <Grid.Row>
          <Grid.Column>
            {isError && (
              <WebcastRestrictionsError error={error} indicoId={indicoId} />
            )}
            {isLoading && (
              <Segment basic>
                <Dimmer active>
                  <Loader>Checking webcast restrictions...</Loader>
                </Dimmer>
              </Segment>
            )}
            {isProtectedData?.result &&
              isProtectedData?.result?.is_restricted && (
                <ProtectedWebcast
                  indicoId={indicoId}
                  streamType="dual"
                  withCdn
                />
              )}
            {isProtectedData?.result &&
              !isProtectedData?.result?.is_restricted && (
                <PublicWebcast indicoId={indicoId} streamType="dual" withCdn />
              )}
          </Grid.Column>
        </Grid.Row>
      </Segment>
    </ResponsiveContainer>
  );
}
