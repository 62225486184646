import React, { useState, useEffect } from "react";
import { Route, useRouteMatch, useParams, Switch } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Menu,
  Segment,
  Container,
  Header,
  Card,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import PermanentWebcastDetails from "../PermanentWebcastDetails/PermanentWebcastDetails/PermanentWebcastDetails";
import TopMenu from "components/TopMenu/TopMenu";
import { usePermanentWebcastCategories } from "hooks/webcasts/use-webcasts";
import ResponsiveContainer from "pages/ResponsiveContainer";
import { IWebcast, IWebcastCategory } from "types/webcast";

interface IParams {
  categoryId: string;
}

function PermanentWebcastsList() {
  const { categoryId } = useParams<IParams>();
  const { data } = usePermanentWebcastCategories();

  const [selectedCategory, setSelectedCategory] = useState<
    IWebcastCategory | undefined
  >(undefined);
  const { url } = useRouteMatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    console.log("Running useEffect to set the selected category");
    if (data && data.results.length > 0 && categoryId !== undefined) {
      const category = data.results.find(
        (x) => x.id === parseInt(categoryId, 10),
      );
      setSelectedCategory(category);
    }
  }, [data, categoryId]);

  if (!selectedCategory) {
    return null;
  }

  if (selectedCategory) {
    return (
      <div>
        <Switch>
          <Route exact path={path}>
            <Header as="h3">
              Permanent live events on category {selectedCategory.name}
            </Header>
            <Card.Group itemsPerRow={4} stackable>
              {selectedCategory &&
                selectedCategory.streams.map(
                  (webcast: IWebcast, index: number) => (
                    <Card
                      key={`webcast-${index.toString()}`}
                      as={Link}
                      to={`${url}/${webcast.id}`}
                      header={webcast.title}
                      image={webcast.default_img}
                    />
                  ),
                )}
            </Card.Group>
          </Route>
          <Route path={`${path}/:webcastId`}>
            <PermanentWebcastDetails selectedCategory={selectedCategory} />
          </Route>
        </Switch>
      </div>
    );
  }
  return null;
}

export default function PermanentWebcasts() {
  const { data, isLoading, isError } = usePermanentWebcastCategories();
  const { path } = useRouteMatch();

  useEffect(() => {
    document.body.style.backgroundColor = "white";
  }, []);

  if (isLoading) {
    return (
      <Segment basic textAlign="center">
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </Segment>
    );
  }

  if (isError) {
    return (
      <Segment basic textAlign="center">
        Unable to fetch the webcast details
      </Segment>
    );
  }

  return (
    <ResponsiveContainer>
      <TopMenu />
      <Segment basic>
        <Container>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" content="Permanent Live Events" icon="video" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <Menu fluid vertical stackable>
                  {data &&
                    data.results.map((category, index) => (
                      <Menu.Item
                        key={`cat-${index.toString()}`}
                        as={NavLink}
                        to={`${path}/${category.id}`}
                        name={category.name}
                      />
                    ))}
                </Menu>
              </Grid.Column>

              <Grid.Column stretched width={12}>
                <Segment>
                  <Switch>
                    <Route exact path={path}>
                      <div>Please, select a category on the menu.</div>
                    </Route>
                    <Route path={`${path}/:categoryId`}>
                      <PermanentWebcastsList />
                    </Route>
                  </Switch>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </ResponsiveContainer>
  );
}
