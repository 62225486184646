import { createMedia } from "@artsy/fresnel";

export const breakPoints = {
  mobile: 320,
  bigMobile: 500,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920,
};

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: breakPoints,
});

export { MediaContextProvider, Media };
