import React, { useEffect, useState } from "react";
import WebcastContent from "../WebcastContent/WebcastContent";
import WebcastErrorContent from "../WebcastErrorContent/WebcastErrorContent";
import WebcastLoadingContent from "../WebcastLoadingContent/WebcastLoadingContent";
import { useWebcast } from "hooks/webcasts/use-webcasts";
import AuthService from "services/auth-service";
import { IStreamType, IWebcast } from "types/webcast";

interface Props {
  indicoId: string;
  streamType: IStreamType;
  withCdn?: boolean;
}

export default function ProtectedWebcast({
  indicoId,
  streamType,
  withCdn = false,
}: Readonly<Props>) {
  const [webcast, setWebcast] = useState<IWebcast | undefined>(undefined);
  const [allowed, setAllowed] = useState<boolean>(true);

  const {
    data: protectedWebcast,
    error,
    isFetching,
    refetch,
  } = useWebcast(`i${indicoId}`);

  useEffect(() => {
    // Set the webcast after fetching
    if (protectedWebcast?.result) {
      if (protectedWebcast.success) {
        setWebcast(protectedWebcast.result);
      } else {
        setAllowed(false);
      }
    }
  }, [protectedWebcast]);

  useEffect(() => {
    document.body.style.backgroundColor = "#060606";
    if (webcast) {
      document.title = `${webcast.title} - CERN Live Events Website (Webcast)`;
    }
  }, [webcast]);

  useEffect(() => {
    // Refetch the webcast after the user logs in
    if (AuthService.isLoggedIn()) {
      refetch();
    }
  }, [refetch]);

  return (
    <div data-testid="protected-webcast">
      {error && <WebcastErrorContent error={error} indicoId={indicoId} />}
      {!allowed && <WebcastErrorContent error={error} indicoId={indicoId} />}
      {isFetching && <WebcastLoadingContent />}
      {webcast && (
        <WebcastContent
          webcast={webcast}
          streamType={streamType}
          withCdn={withCdn}
        />
      )}
    </div>
  );
}
