import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import config from "config";

export default function DevBanner() {
  const { IS_DEV_INSTALL } = config;
  if (!IS_DEV_INSTALL) {
    return null;
  }

  return (
    <Menu color="yellow" inverted widths={1} className="yellowMenu">
      <Menu.Item color="yellow">
        <Icon name="exclamation triangle" /> This is a DEV Installation
      </Menu.Item>
    </Menu>
  );
}
