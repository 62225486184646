import React, { ReactNode, useEffect } from "react";
import PropTypes from "prop-types";
import ResponsiveContainer from "../ResponsiveContainer";
import HomepageHeading from "./components/HomePageHeading";

interface IProps {
  children: ReactNode;
}

function HomeResponsiveContainer({ children }: IProps) {
  useEffect(() => {
    document.body.style.backgroundColor = "white";
  }, []);
  return (
    <ResponsiveContainer headerComponent={<HomepageHeading />}>
      {children}
    </ResponsiveContainer>
  );
}

HomeResponsiveContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default HomeResponsiveContainer;
