/**
 * Display only a limited part of a text, trimming it by words.
 *
 * @param {string} string The text that will be trimmed
 * @param {number} maxWords The maximum number of words to display
 */
export function readMore(string, maxWords) {
  const strippedString = string.replace(/<[^>]*>?/gm, "").trim();
  const array = strippedString.split(" ");
  const wordCount = array.length;
  let newString = string;
  newString = array.splice(0, maxWords).join(" ");

  if (wordCount > maxWords) {
    newString += "...";
  }

  return newString;
}

export function cleanString(string) {
  const strippedString = string.replace(/<[^>]*>?/gm, "").trim();
  return strippedString;
}
