import keycloak from "keycloak";

const initKeycloak = (onLoadCallback: any) => {
  keycloak.onReady = (authenticated: any) => {
    onLoadCallback(authenticated);
  };
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: encodeURI(
        `${window.location.origin}/silent-check-sso.html`,
      ),
    })
    .then((authenticated: boolean) => {
      if (!authenticated) {
        console.log("User is not authenticated");
      }
    })
    .catch(console.error);
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback: any) =>
  keycloak.updateToken(30).then(successCallback).catch(doLogin);

const getUsername = () => {
  const tokenParsed = keycloak.tokenParsed as any;

  return tokenParsed?.preferred_username;
};

const getFirstName = () => {
  const tokenParsed = keycloak.tokenParsed as any;

  return tokenParsed?.given_name;
};

const getLastName = () => {
  const tokenParsed = keycloak.tokenParsed as any;

  return tokenParsed?.family_name;
};

const hasRole = (roles: any) =>
  roles.some((role: any) => keycloak.hasRealmRole(role));

export default {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFirstName,
  getLastName,
  hasRole,
};
