import { QueryCache, QueryClient } from "react-query";

const queryCache = new QueryCache({});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      // staleTime: 0, // 1000 * 60, // 60 seconds
    },
  },
  queryCache,
});

export default queryClient;
