import React from "react";
import Latex from "react-latex-next";
import { useHistory } from "react-router";
import {
  Item,
  Button,
  Icon,
  Image,
  Segment,
  Loader,
  Dimmer,
  Message,
  MessageHeader,
} from "semantic-ui-react";
import EventDateText from "components/EventDateText/EventDateText";
import { useUpcomingWebcasts } from "hooks/webcasts/use-webcasts";
import { readMore } from "utils/string_utils";

export default function UpcomingWebcasts() {
  const { data, error, isLoading } = useUpcomingWebcasts();
  const history = useHistory();

  if (isLoading) {
    return (
      <Segment basic>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }
  if (error) {
    return (
      <Message negative>
        <MessageHeader>
          Unable to fetch the upcoming events (Error {error.response?.status})
        </MessageHeader>
        <p>
          We encountered an error while retrieving the upcoming events from the
          API.
        </p>
      </Message>
    );
  }

  const goToWebcast = (indicoId: string) => {
    console.log(`Load webcast ${indicoId}`);
    history.push(`/event/i${indicoId}`);
  };

  if (isLoading) {
    return (
      <Segment basic textAlign="center">
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </Segment>
    );
  }

  if (error) {
    return (
      <Segment basic textAlign="center">
        Unable to fetch the upcoming live events
      </Segment>
    );
  }

  if (data && data.results && data.results.length === 0) {
    return (
      <Segment basic textAlign="center">
        There are no upcoming live events.
      </Segment>
    );
  }

  return (
    <Item.Group divided>
      {data &&
        data.results &&
        data.results.map((webcast) => (
          <Item key={`upcoming-${webcast.id}`}>
            <Item.Image
              size="small"
              src={webcast.img}
              alt={`Image of ${webcast.title}`}
            />
            <Item.Content>
              <Item.Header as="h3" style={{ fontSize: "1.5em" }}>
                <Latex>{webcast.title}</Latex>
              </Item.Header>
              {webcast.speakers && <Item.Meta>by {webcast.speakers}</Item.Meta>}
              <Item.Description>
                <EventDateText
                  startDate={webcast.startDate.day}
                  startTime={webcast.startDate.time}
                  timezone={webcast.startDate.tz}
                  endDate={webcast.endDate.day}
                  endTime={webcast.endDate.time}
                />
              </Item.Description>
              <Item.Description>
                <span>
                  <Icon name="location arrow" verticalAlign="middle" />{" "}
                  {webcast.room_name ? webcast.room_name : "-"}
                </span>
              </Item.Description>
              {!webcast.restricted ? (
                <Item.Extra className="accExtra">
                  {readMore(webcast.abstract, 40)}
                </Item.Extra>
              ) : null}
              <Item.Extra className="accExtra">
                <Button
                  primary
                  floated="right"
                  className="cernPrimary"
                  onClick={() => goToWebcast(webcast.indico_id)}
                >
                  {webcast.restricted ? <Icon name="lock" /> : ""} More
                  Information
                  <Icon name="chevron right" />
                </Button>
                <a href={webcast.indico_link} title="Event link">
                  <Image
                    src="/images-client/indico.svg"
                    alt="Indico Logo"
                    size="tiny"
                  />
                </a>
              </Item.Extra>
            </Item.Content>
          </Item>
        ))}
    </Item.Group>
  );
}
