import React, { useState, useEffect } from "react";
import Latex from "react-latex-next";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { chunk } from "lodash";
import {
  Grid,
  Item,
  Button,
  Icon,
  Segment,
  Dimmer,
  Loader,
  Label,
  Image,
  Container,
  Divider,
  Message,
  MessageHeader,
} from "semantic-ui-react";
import EventDateText from "components/EventDateText/EventDateText";
import { useLiveWebcasts } from "hooks/webcasts/use-webcasts";
import { IWebcast } from "types/webcast";
import { readMore } from "utils/string_utils";

export default function LiveWebcasts() {
  const { data, error, isLoading } = useLiveWebcasts();
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>(1);
  const history = useHistory();

  const goToWebcast = (indicoId: string) => {
    console.log(`Load webcast ${indicoId}`);
    history.push(`/event/i${indicoId}`);
  };

  useEffect(() => {
    if (data && data.results.length > 0) {
      const gridColumns = data.results.length > 1 ? 2 : 1;
      setColumns(gridColumns);
      setRows(chunk(data.results, gridColumns));
    }
  }, [columns, setRows, data]);

  const getWebcastColum = (webcast: IWebcast) => (
    <Grid.Column key={`live-webcast-${webcast.id}`}>
      <Item.Group>
        <Segment raised inverted>
          <Label color="red">
            <Icon name="video camera" /> LIVE
          </Label>
          {webcast.restricted ? (
            <Icon name="lock" style={{ marginLeft: 10 }} />
          ) : (
            ""
          )}
          <Item>
            <Item.Content>
              <Item.Header
                as="h2"
                style={{ fontSize: "1.5em", marginTop: 10 }}
                onClick={() => goToWebcast(webcast.indico_id)}
                title="Watch this webcast"
              >
                <Latex>{webcast.title}</Latex>
              </Item.Header>
              {webcast.speakers && (
                <Item.Meta style={{ marginBottom: 10 }}>
                  by {webcast.speakers}
                </Item.Meta>
              )}
              <Item.Description>
                <EventDateText
                  startDate={webcast.startDate.day}
                  startTime={webcast.startDate.time}
                  timezone={webcast.startDate.tz}
                  endDate={webcast.endDate.day}
                  endTime={webcast.endDate.time}
                />
              </Item.Description>
              <Item.Description>
                <span>
                  <Icon name="location arrow" verticalAlign="middle" />{" "}
                  {webcast.room_name ? webcast.room_name : "-"}
                </span>
              </Item.Description>
              {!webcast.restricted ? (
                <Item.Extra>{readMore(webcast.abstract, 40)}</Item.Extra>
              ) : null}
              <Item.Description style={{ marginTop: 20 }}>
                <Item.Extra>
                  <Button
                    primary
                    floated="right"
                    className="cernPrimary"
                    onClick={() => goToWebcast(webcast.indico_id)}
                  >
                    {webcast.restricted ? <Icon name="lock" /> : ""} Watch
                    webcast
                    <Icon name="chevron right" />
                  </Button>
                  <a href={webcast.indico_link} title="Event link">
                    <Image
                      src="/images-client/indico_white.png"
                      alt="Indico Logo"
                      size="tiny"
                    />
                  </a>
                </Item.Extra>
              </Item.Description>
            </Item.Content>
          </Item>
        </Segment>
      </Item.Group>
    </Grid.Column>
  );

  if (isLoading) {
    return (
      <Segment basic textAlign="center">
        <Dimmer active inverted>
          <Loader>Loading...</Loader>
        </Dimmer>
      </Segment>
    );
  }

  if (error) {
    return (
      <Segment basic>
        <Message negative>
          <MessageHeader>
            Unable to fetch live events (Error {error.response?.status})
          </MessageHeader>
          <p>
            We encountered an error while retrieving the live events from the
            API.
          </p>
        </Message>
      </Segment>
    );
  }

  return (
    <header>
      {rows && rows.length > 0 && (
        <Container
          fluid
          className="live-background"
          style={{
            padding: "1em 0 1em 0",
            marginTop: -8,
          }}
        >
          <div className="live-not-blurred">
            <Container text={columns === 1}>
              <Divider
                as="h2"
                className="header"
                id="live-webcasts"
                horizontal
                inverted
                style={{
                  margin: "1em 0em 2em 0em",
                  textTransform: "uppercase",
                }}
              >
                Now live
              </Divider>
              <Grid columns={columns} stackable>
                {rows.map((cols: IWebcast[], index: number) => (
                  <Grid.Row key={`recent-col-${index.toString()}`}>
                    {cols &&
                      cols.map((record: IWebcast) => getWebcastColum(record))}
                  </Grid.Row>
                ))}
              </Grid>
            </Container>

            {rows.length === 0 && null}
          </div>
        </Container>
      )}
    </header>
  );
}
