import React, { useEffect, useState } from "react";
import WebcastContent from "../WebcastContent/WebcastContent";
import WebcastErrorContent from "../WebcastErrorContent/WebcastErrorContent";
import WebcastLoadingContent from "../WebcastLoadingContent/WebcastLoadingContent";
import { usePublicWebcast } from "hooks/webcasts/use-webcasts";
import { IStreamType, IWebcast } from "types/webcast";

interface Props {
  indicoId: string;
  streamType: IStreamType;
  withCdn?: boolean;
}

export default function PublicWebcast({
  indicoId,
  streamType,
  withCdn = false,
}: Readonly<Props>) {
  const [webcast, setWebcast] = useState<IWebcast | undefined>(undefined);

  const {
    data: webcastData,
    error,
    isFetching,
  } = usePublicWebcast(`i${indicoId}`);

  useEffect(() => {
    // Set the webcast after fetching
    if (webcastData?.result) {
      setWebcast(webcastData.result);
    }
  }, [webcastData]);

  useEffect(() => {
    document.body.style.backgroundColor = "#060606";
    if (webcast) {
      document.title = `${webcast.title} - CERN Live Events Website (Webcast)`;
    }
  }, [webcast]);

  return (
    <div data-testid="public-webcast">
      {error && <WebcastErrorContent error={error} indicoId={indicoId} />}
      {isFetching && <WebcastLoadingContent />}
      {webcast && (
        <WebcastContent
          webcast={webcast}
          streamType={streamType}
          withCdn={withCdn}
        />
      )}
    </div>
  );
}
