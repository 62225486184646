import React from "react";
import { Link } from "react-router-dom";
import {
  Segment,
  Container,
  Grid,
  List,
  Image,
  Header,
  Icon,
} from "semantic-ui-react";
import { PACKAGE_VERSION } from "version";

export function Footer() {
  return (
    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <Container>
        <Grid stackable columns={4}>
          <Grid.Row>
            <Grid.Column>
              <List link inverted role="list">
                <List.Item>
                  <a href="//home.cern" title="CERN Website">
                    <Image
                      src="/images-client/LogoOutline-White.svg"
                      alt="CERN logo in white"
                      width={100}
                    />
                  </a>
                </List.Item>
                <List.Item>{PACKAGE_VERSION}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header inverted size="small" content="About" />
              <List link inverted role="list">
                <List.Item>
                  <a
                    href="https://cern.service-now.com/service-portal?id=service_element&name=webcast"
                    title="Webcast Service Website"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="video" /> Webcast Service
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="https://webcast.docs.cern.ch"
                    title="Webcast Service Documentation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="help" /> Documentation
                  </a>
                </List.Item>
                <List.Item>
                  <Link to="/event/i0" title="Webcast Test Channel">
                    <Icon name="play" /> Webcast Test Channel
                  </Link>
                </List.Item>
                <List.Item>
                  <Link to="/permanent-webcasts" title="Permanent live events">
                    <Icon name="play" /> Permanent live events
                  </Link>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header inverted size="small" content="Subscribe" />
              <List link inverted role="list">
                <List.Item>
                  <a href="/feeds/" title="RSS feed for all events">
                    <Icon name="rss" /> All events
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="/feeds/?feed_type=live"
                    title="RSS feed for live events"
                  >
                    <Icon name="rss" /> Live events
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="/feeds/?feed_type=upcoming"
                    title="RSS feed for upcoming events"
                  >
                    <Icon name="rss" /> Upcoming events
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header inverted size="small" content="Contact" />
              <List link inverted role="list">
                <List.Item>
                  <a
                    href="https://cern.service-now.com/service-portal?id=sc_cat_item&name=incident&fe=webcast&s=webcast"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Report an issue with the Webcast"
                  >
                    <Icon name="help" /> Report an issue
                  </a>
                </List.Item>
                <List.Item>
                  <a
                    href="http://home.cern/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Contact CERN"
                  >
                    <Icon name="chat" /> Contact CERN
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4} />
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}

export default Footer;
