import React, { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Visibility,
  Segment,
  Menu,
  Container,
  Header,
  Image,
  Sidebar,
  Icon,
} from "semantic-ui-react";
import { Media } from "app-media";
import DevBanner from "components/DevBanner/DevBanner";
import { Footer } from "components/Footer/Footer";

interface IProps {
  headerComponent?: ReactNode | null;
  children: ReactNode;
}
function ResponsiveContainer({ headerComponent, children }: IProps) {
  const [fixed, setFixed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="push"
        inverted
        onHide={() => setSidebarOpen(false)}
        vertical
        visible={sidebarOpen}
        direction="right"
      >
        <Menu.Item as={NavLink} exact to="/" title="Home">
          Home
        </Menu.Item>
        <Menu.Item
          as="a"
          href="https://webcast.docs.cern.ch"
          title="Webcast Service Documentation"
          rel="noopener noreferrer"
        >
          Webcast Service Docs
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          exact
          to="/permanent-webcasts"
          title="Permanent Live Events"
        >
          Permanent Live Events
        </Menu.Item>
        <Menu.Item
          as="a"
          href="https://cds.cern.ch"
          title="CERN Document Server"
          rel="noopener noreferrer"
        >
          CDS Archive
        </Menu.Item>
        <Menu.Item
          as="a"
          href="https://www.youtube.com/@CERN/streams"
          title="Youtube"
          rel="noopener noreferrer"
        >
          Youtube
        </Menu.Item>
      </Sidebar>
      <Sidebar.Pusher dimmed={sidebarOpen}>
        <header role="banner">
          <Visibility
            once={false}
            onBottomPassed={() => setFixed(true)}
            onBottomPassedReverse={() => setFixed(false)}
          >
            <Segment
              inverted
              textAlign="center"
              style={{
                padding: "1em 0em",
                backgroundColor: "#004c93",
                marginTop: 40,
                borderBottom: "1em rgb(0 34 67) solid",
              }}
              vertical
            >
              <nav role="navigation">
                <Menu
                  inverted={!fixed}
                  pointing={!fixed}
                  secondary={!fixed}
                  size="large"
                  className="mainMenu"
                >
                  <Container>
                    <Menu.Item>
                      <Header as="h1" inverted>
                        <Image
                          src="/images-client/LogoOutline-White.svg"
                          alt="CERN Logo"
                          width="200"
                          height="200"
                        />
                        <Header.Content>
                          CERN
                          <Header.Subheader>Webcast Website</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Menu.Item>

                    <Menu.Item
                      position="right"
                      as={Media}
                      greaterThanOrEqual="tablet"
                    >
                      <Menu.Item as={NavLink} exact to="/">
                        Home
                      </Menu.Item>

                      <Menu.Item
                        as="a"
                        href="https://webcast.docs.cern.ch/"
                        title="Webcast Service Documentation"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Webcast Service Docs{" "}
                        <Icon name="external" style={{ marginLeft: 10 }} />
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        href="https://cds.cern.ch"
                        title="CERN Document Server"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CDS Archive{" "}
                        <Icon name="external" style={{ marginLeft: 10 }} />
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        href="https://www.youtube.com/@CERN/streams"
                        title="Youtube Channel"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Youtube{" "}
                        <Icon name="external" style={{ marginLeft: 10 }} />
                      </Menu.Item>
                    </Menu.Item>
                    <Menu.Item as={Media} lessThan="tablet">
                      <Menu.Item onClick={() => setSidebarOpen(true)}>
                        <Icon name="sidebar" />
                      </Menu.Item>
                    </Menu.Item>
                  </Container>
                </Menu>
              </nav>
            </Segment>
          </Visibility>
        </header>

        <Container fluid>
          <DevBanner />
        </Container>

        {headerComponent}

        <main role="main">{children}</main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
}

ResponsiveContainer.defaultProps = {
  headerComponent: null,
};

export default ResponsiveContainer;
