import React from "react";
import { NavLink } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import { IWebcast } from "types/webcast";

interface IProps {
  webcast: IWebcast;
  streamType: string;
  handleDisplayFluid: Function;
  displayFluid: boolean;
}
export default function ViewSelector({
  webcast,
  streamType,
  handleDisplayFluid,
  displayFluid,
}: IProps) {
  console.log(streamType);

  const toggleWidthAction = () => {
    handleDisplayFluid();
  };

  if (!webcast || !webcast.stream) {
    return <div>Loading...</div>;
  }

  return (
    <Menu inverted>
      {webcast.stream.type === "Camera & Slides" && (
        <Menu.Item as={NavLink} to={`/event/i${webcast.indico_id}`} exact>
          <Icon name="video" />
          <Icon name="film" /> Main view
        </Menu.Item>
      )}
      {(webcast.stream.type === "Camera & Slides" ||
        webcast.stream.type === "camera") && (
        <Menu.Item as={NavLink} to={`/event/i${webcast.indico_id}/camera`}>
          <Icon name="video" /> Camera
        </Menu.Item>
      )}
      {(webcast.stream.type === "Camera & Slides" ||
        webcast.stream.type === "slides") && (
        <Menu.Item as={NavLink} to={`/event/i${webcast.indico_id}/slides`}>
          <Icon name="film" /> Slides
        </Menu.Item>
      )}
      <Menu.Menu position="right">
        <Menu.Item onClick={toggleWidthAction}>
          <Icon name={displayFluid ? "compress" : "expand"} />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}
