const hlsLivePlugin = "hlsLive";
const hlsVodPlugin = "hls";
/**
 * Generate a manifest (data.json) for stream using the provided title, camera and slides source.
 *
 * @param {string} title The title of the stream
 * @param {string} cameraSrc URL of the camera's stream (presenter)
 * @param {string} slidesSrc URL of the slides's stream (presentation)
 * @returns
 */
export function createLiveCameraSlidesManifest(
  title: string,
  cameraSrc: string,
  slidesSrc: string,
  useLivePlugin: boolean,
) {
  console.log(`useLivePlugin: ${useLivePlugin}`);
  const plugin = useLivePlugin ? hlsLivePlugin : hlsVodPlugin;
  const jsonContent = `{
    "metadata": {
      "title": "${title}",
      "duration": 1,
      "preview": "/images/preview.jpeg",
      "visibleTimeLine": ${!useLivePlugin}
    },
    "streams": [
      {
        "sources": {
          "${plugin}": [
            {
              "src": "${cameraSrc}",
              "mimetype": "video/mp4",
              "isLiveStream": true,
              "res": {
                "w": "1920",
                "h": "1080"
              }
            }
          ]
        },
        "content": "presenter",
        "role": "mainAudio"
      },
      {
        "sources": {
          "${plugin}": [
            {
              "src": "${slidesSrc}",
              "mimetype": "video/mp4",
              "isLiveStream": true,
              "res": {
                "w": "1920",
                "h": "1080"
              }
            }
          ]
        },
        "content": "presentation"
      }
    ]
  }`;
  return jsonContent;
}

/**
 * Generate a manifest (data.json) for stream using the provided title, camera source.
 *
 * @param {string} title The title of the stream
 * @param {string} cameraSrc URL of the camera's stream (presenter)
 * @returns
 */
export function createLiveCameraManifest(
  title: string,
  cameraSrc: string,
  useLivePlugin: boolean,
) {
  const plugin = useLivePlugin ? hlsLivePlugin : hlsVodPlugin;

  const jsonContent = `{
    "metadata": {
      "title": "${title}",
      "duration": 1,
      "preview": "/images/preview.jpeg",
      "visibleTimeLine": ${!useLivePlugin}
    },
    "streams": [
      {
        "sources": {
          "${plugin}": [
            {
              "src": "${cameraSrc}",
              "mimetype": "video/mp4",
              "isLiveStream": true,
              "res": {
                "w": "1920",
                "h": "1080"
              }
            }
          ]
        },
        "content": "presenter",
        "role": "mainAudio"
      }
    ]
  }`;
  return jsonContent;
}

/**
 * Generate a manifest (data.json) for stream using the provided title and slides source.
 *
 * @param {string} title The title of the stream
 * @param {string} slidesSrc URL of the slides's stream (presentation)
 * @returns
 */
export function createLiveSlidesManifest(
  title: string,
  slidesSrc: string,
  useLivePlugin: boolean,
) {
  const plugin = useLivePlugin ? hlsLivePlugin : hlsVodPlugin;

  const jsonContent = `{
    "metadata": {
      "title": "${title}",
      "duration": 1,
      "preview": "/images/preview.jpeg",
      "visibleTimeLine": ${!useLivePlugin}
    },
    "streams": [
      {
        "sources": {
          "${plugin}": [
            {
              "src": "${slidesSrc}",
              "mimetype": "video/mp4",
              "isLiveStream": true,
              "res": {
                "w": "1920",
                "h": "1080"
              }
            }
          ]
        },
        "content": "presentation",
        "role": "mainAudio"
      }
    ]
  }`;
  return jsonContent;
}
