import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Latex from "react-latex-next";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { chunk } from "lodash";
import {
  Grid,
  Item,
  Button,
  Icon,
  Segment,
  Dimmer,
  Loader,
  Image,
  Message,
  MessageHeader,
} from "semantic-ui-react";
import EventDateText from "components/EventDateText/EventDateText";
import { useRecentWebcasts } from "hooks/webcasts/use-webcasts";
import { IWebcast } from "types/webcast";

interface IProps {
  columns: number;
}

export default function RecentWebcasts({ columns }: IProps) {
  const history = useHistory();
  const { data, error, isLoading } = useRecentWebcasts();
  const [rows, setRows] = useState<any>([]);

  useEffect(() => {
    if (data?.results) {
      setRows(chunk(data.results, columns));
    }
  }, [columns, setRows, data]);

  const goToWebcast = (indicoId: string) => {
    history.push(`/event/i${indicoId}`);
  };

  const getWebcastColum = (webcast: IWebcast, index: number) => (
    <Grid.Column key={`recent-${index}`}>
      <Item.Group>
        <Item>
          <Item.Image
            size="tiny"
            src={webcast.img}
            alt={`Image of ${webcast.title}`}
          />
          <Item.Content>
            <Item.Header as="h3" style={{ fontSize: "1.2em" }}>
              <Latex>{webcast.title}</Latex>
            </Item.Header>
            {webcast.speakers && <Item.Meta>by {webcast.speakers}</Item.Meta>}
            <Item.Description>
              <EventDateText
                startDate={webcast.startDate.day}
                startTime={webcast.startDate.time}
                timezone={webcast.startDate.tz}
                endDate={webcast.endDate.day}
                endTime={webcast.endDate.time}
              />
            </Item.Description>
            <Item.Description>
              <span>
                <Icon name="location arrow" verticalAlign="middle" />{" "}
                {webcast.room_name ? webcast.room_name : "-"}
              </span>
            </Item.Description>
            <Item.Extra className="accExtra">
              {webcast.has_dvr && (
                <Button
                  primary
                  size="tiny"
                  floated="right"
                  className="cernPrimary"
                  onClick={() => goToWebcast(webcast.indico_id)}
                >
                  {webcast.restricted ? <Icon name="lock" /> : ""} Watch again
                  <Icon name="chevron right" />
                </Button>
              )}
              <a href={webcast.indico_link} title="Event link">
                <Image
                  src="/images-client/indico.svg"
                  alt="Indico Logo"
                  size="tiny"
                />
              </a>
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </Grid.Column>
  );

  if (isLoading) {
    return (
      <Segment basic textAlign="center">
        <Dimmer active inverted>
          <Loader>Loading...</Loader>
        </Dimmer>
      </Segment>
    );
  }

  if (error) {
    return (
      <Message negative>
        <MessageHeader>
          Unable to fetch recent events (Error {error.response?.status})
        </MessageHeader>
        <p>
          We encountered an error while retrieving the recent events from the
          API.
        </p>
      </Message>
    );
  }

  return (
    <>
      {rows && rows.length > 0 && (
        <Grid columns={2} divided>
          {rows.map((cols: IWebcast[], index: number) => (
            <Grid.Row key={`recent-col-${index.toString()}`}>
              {cols.map((webcast: IWebcast, index2: number) =>
                getWebcastColum(webcast, index2),
              )}
            </Grid.Row>
          ))}
        </Grid>
      )}
      {rows.length === 0 && (
        <Segment basic textAlign="center">
          There are no recent live events.
        </Segment>
      )}
    </>
  );
}
