import React from "react";
import { useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import ProtectedPermanentEmbed from "./components/ProtecedPermanentEmbed/ProtectedPermanentEmbed";
import PublicPermanentEmbed from "./components/PublicPermanentEmbed/PublicPermanentEmbed";
import TopMenu from "components/TopMenu/TopMenu";
import { useIsProtectedWebcast } from "hooks/webcasts/use-webcasts";

interface IParams {
  indicoId: string;
}

export default function PermanentEmbedPage() {
  const { indicoId } = useParams<IParams>();

  const {
    data: isProtectedData,
    error,
    isFetching,
  } = useIsProtectedWebcast(`i${indicoId}`);

  return (
    <>
      <TopMenu />
      {error && (
        <div style={{ marginTop: 50 }}>
          <Segment inverted>Error getting webcast restrictions.</Segment>
        </div>
      )}
      {isFetching && (
        <div style={{ marginTop: 50 }}>
          <Segment inverted>Checking webcast restrictions...</Segment>
        </div>
      )}
      {isProtectedData &&
        isProtectedData.result &&
        isProtectedData.result.is_restricted && (
          <ProtectedPermanentEmbed indicoId={indicoId} />
        )}
      {isProtectedData &&
        isProtectedData.result &&
        !isProtectedData.result.is_restricted && (
          <PublicPermanentEmbed indicoId={indicoId} />
        )}
    </>
  );
}
