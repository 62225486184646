export function getUrlParameter(name: string) {
  // Optional: implement this using a fallback to support IE11
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(name) ? urlParams.get(name) : null;
}

export function isDomainAllowed(url: string, allowedDomains: string[]) {
  const urlObject = new URL(url);
  const isAllowed = allowedDomains.indexOf(urlObject.host) > -1;
  console.log(`Domain ${urlObject.host} is allowed: ${isAllowed}`);
  return isAllowed;
}

export function cleanString(originalString: string | null) {
  if (!originalString) {
    return null;
  }
  const strippedString = originalString.replace(/(<([^>]+)>)/gi, "");
  return strippedString;
}

export default getUrlParameter;
