import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import PermanentEmbedPlayer from "../PermanentEmbedPlayer/PermanentEmbedPlayer";
import { usePermanentWebcast } from "hooks/webcasts/use-webcasts";
import { IWebcast } from "types/webcast";

interface Props {
  indicoId: string;
}

export default function PublicPermanentEmbed({ indicoId }: Props) {
  const [webcast, setWebcast] = useState<IWebcast | undefined>(undefined);

  const {
    data: webcastData,
    error,
    isFetching,
  } = usePermanentWebcast(indicoId);

  useEffect(() => {
    // Set the webcast after fetching
    if (webcastData && webcastData.result) {
      setWebcast(webcastData.result);
    }
  }, [webcastData]);

  useEffect(() => {
    document.body.style.backgroundColor = "#060606";
    if (webcast) {
      document.title = `${webcast.title} - CERN Live Events Website (Webcast)`;
    }
  }, [webcast]);

  return (
    <div data-testid="public-webcast">
      {error && <Segment>Unable to display the webcast</Segment>}
      {isFetching && <Segment>Loading webcast</Segment>}
      {webcast && (
        <div>
          <PermanentEmbedPlayer
            title={webcast.stream.title}
            streamSrc={webcast.stream.camera_src}
          />
        </div>
      )}
    </div>
  );
}
