import React from "react";
import ReactPiwik from "react-piwik";
import { QueryClientProvider } from "react-query";
import { Router, Route, Switch } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from "history";
import { Dimmer, Loader } from "semantic-ui-react";
import queryClient from "./api/query-client";
import config from "./config";
import HomePage from "./pages/HomePage/HomePage";
import PermanentWebcasts from "./pages/PermanentWebcastsPage/PermanentWebcasts";
import WebcastPage from "./pages/WebcastPage/WebcastPage";
import EmbedPage from "pages/EmbedPage/EmbedPage";
import PermanentEmbedPage from "pages/PermanentEmbedPage/PermanentEmbedPage";
import PreviewPage from "pages/PreviewPage/PreviewPage";
import WebcastCameraCdnPage from "pages/WebcastCameraCdnPage/WebcastCameraCdnPage";
import WebcastCameraPage from "pages/WebcastCameraPage/WebcastCameraPage";
import WebcastCdnPage from "pages/WebcastCdnPage/WebcastCdnPage";
import WebcastSlidesCdnPage from "pages/WebcastSlidesCdnPage/WebcastSlidesCdnPage";
import WebcastSlidesPage from "pages/WebcastSlidesPage/WebcastSlidesPage";

interface Props {
  initialized: boolean;
}

const { PIWIK_ID } = config;

const piwik = new ReactPiwik({
  url: "webanalytics.web.cern.ch",
  siteId: Number(PIWIK_ID),
});

const history = createBrowserHistory();

function App({ initialized }: Readonly<Props>) {
  if (!initialized) {
    return (
      <div style={{ height: 100, width: 100 }}>
        <Dimmer active inverted>
          <Loader inverted>Contacting SSO...</Loader>
        </Dimmer>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={piwik.connectToHistory(history)}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          {/* Beware order matters */}
          <Route
            path="/event/i:indicoId/camera"
            component={WebcastCameraPage}
          />
          <Route
            path="/event/i:indicoId/camera/cdn"
            component={WebcastCameraCdnPage}
          />
          <Route
            path="/event/i:indicoId/slides"
            component={WebcastSlidesPage}
          />
          <Route
            path="/event/i:indicoId/slides/cdn"
            component={WebcastSlidesCdnPage}
          />
          <Route exact path="/event/i:indicoId" component={WebcastPage} />
          <Route
            exact
            path="/event/i:indicoId/cdn"
            component={WebcastCdnPage}
          />
          <Route exact path="/embed/i:indicoId" component={EmbedPage} />
          <Route exact path="/view/:code/:indicoId" component={PreviewPage} />
          <Route path="/permanent-webcasts" component={PermanentWebcasts} />
          <Route
            exact
            path="/permanent-embed/:indicoId"
            component={PermanentEmbedPage}
          />
          <Route render={() => <div>This page does not exist</div>} />
        </Switch>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
