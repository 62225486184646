import React from "react";
import { Container, Divider, Segment } from "semantic-ui-react";
import CdsRecords from "./components/CdsRecords";
import RecentWebcasts from "./components/RecentWebcasts";
import UpcomingWebcasts from "./components/UpcomingWebcasts/UpcomingWebcasts";
import TopMenu from "components/TopMenu/TopMenu";
import HomeResponsiveContainer from "pages/HomePage/HomeResponsiveContainer";

function HomepageLayout() {
  return (
    <HomeResponsiveContainer>
      <TopMenu />

      <Segment basic>
        <Container text>
          <Divider
            as="h2"
            className="header"
            id="upcoming-webcasts"
            horizontal
            style={{ margin: "1em 0em 2em 0em", textTransform: "uppercase" }}
          >
            Upcoming Live Events
          </Divider>
          <UpcomingWebcasts />
        </Container>
      </Segment>

      <Segment basic>
        <Container>
          <Divider
            as="h2"
            className="header"
            id="recent-webcasts"
            horizontal
            style={{ margin: "1em 0em 2em 0em", textTransform: "uppercase" }}
          >
            Recent Live Events
          </Divider>
          <RecentWebcasts columns={2} />
        </Container>
      </Segment>

      <Segment basic>
        <Container>
          <Divider
            as="h2"
            className="header"
            id="recent-cds-records"
            horizontal
            style={{ margin: "1em 0em 2em 0em", textTransform: "uppercase" }}
          >
            Recent CDS Records
          </Divider>
          <CdsRecords columns={3} />
        </Container>
      </Segment>
    </HomeResponsiveContainer>
  );
}

export default HomepageLayout;
