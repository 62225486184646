import { useQuery } from "react-query";
import { AxiosError } from "axios";
import apiClient from "api/api-headers";
import {
  IProtectedWebcastCheck,
  IWebcastApiResponse,
  IWebcastCategoryListApiResponse,
  IWebcastListApiResponse,
} from "types/webcast";

type webcastTypes = "live" | "recent" | "upcoming";

export const fetchWebcasts = async (webcastType: webcastTypes) => {
  const { data } = await apiClient.get<IWebcastListApiResponse>(
    `/webcasts/?type=${webcastType}`,
  );
  return data;
};

export function useLiveWebcasts(options = {}) {
  return useQuery<IWebcastListApiResponse, AxiosError>(
    "live-webcasts",
    () => fetchWebcasts("live"),
    {
      ...options,
    },
  );
}

export function useUpcomingWebcasts(options = {}) {
  return useQuery<IWebcastListApiResponse, AxiosError>(
    "upcoming-webcasts",
    () => fetchWebcasts("upcoming"),
    {
      ...options,
    },
  );
}

export function useRecentWebcasts(options = {}) {
  return useQuery<IWebcastListApiResponse, AxiosError>(
    "recent-webcasts",
    () => fetchWebcasts("recent"),
    {
      ...options,
    },
  );
}

export const fetchWebcast = async (webcastId: string | undefined) => {
  const { data } = await apiClient.get<IWebcastApiResponse>(
    `/webcasts/${webcastId}`,
  );
  return data;
};

export function useWebcast(webcastId: string | undefined, options = {}) {
  return useQuery<IWebcastApiResponse, AxiosError>(
    ["webcast", webcastId],
    () => fetchWebcast(webcastId),
    {
      enabled: !!webcastId,
      ...options,
    },
  );
}

export const fetchPublicWebcast = async (webcastId: string | undefined) => {
  const { data } = await apiClient.get<any>(`/webcasts/public/${webcastId}`);
  return data;
};

export function usePublicWebcast(webcastId: string | undefined, options = {}) {
  return useQuery<IWebcastApiResponse, AxiosError>(
    ["webcast", webcastId],
    () => fetchPublicWebcast(webcastId),
    {
      enabled: !!webcastId,
      ...options,
    },
  );
}

export const fetchIsProtectedWebcast = async (
  webcastId: string | undefined,
) => {
  const { data } = await apiClient.get<any>(
    `/webcasts/${webcastId}/is-protected`,
  );
  return data;
};

export function useIsProtectedWebcast(
  webcastId: string | undefined,
  options = {},
) {
  return useQuery<IProtectedWebcastCheck, AxiosError>(
    ["webcast-protected", webcastId],
    () => fetchIsProtectedWebcast(webcastId),
    {
      enabled: !!webcastId,
      ...options,
    },
  );
}

export const fetchPermanentWebcast = async (webcastId: string | undefined) => {
  const { data } = await apiClient.get<IWebcastApiResponse>(
    `/webcasts/permanent-webcasts/${webcastId}`,
  );
  return data;
};

export function usePermanentWebcast(
  webcastId: string | undefined,
  options = {},
) {
  return useQuery<IWebcastApiResponse, AxiosError>(
    ["permanent-webcast", webcastId],
    () => fetchPermanentWebcast(webcastId),
    {
      enabled: !!webcastId,
      ...options,
    },
  );
}

export const fetchPermanentWebcastCategories = async () => {
  const { data } = await apiClient.get<any>(`/webcasts/permanent-categories`);
  return data;
};

export function usePermanentWebcastCategories(options = {}) {
  return useQuery<IWebcastCategoryListApiResponse, AxiosError>(
    ["categories"],
    () => fetchPermanentWebcastCategories(),
    {
      ...options,
    },
  );
}
