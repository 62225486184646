import { IAppConfig } from "types/config";

const allowed_domains = [
  "wowza.cern.ch", // CERN
  "wowza05.cern.ch",
  "wowzaqaedge.cern.ch", // CERN QA
  "cern2.vo.llnwd.net", // Limelight
  "eu-west-2b.hls.falcon.eegcloud.tv", // AI Media
];

const dev: IAppConfig = {
  api: {
    ENDPOINT: "http://localhost:5000",
  },
  IS_DEV_INSTALL: process.env.REACT_APP_IS_DEV_INSTALL === "true",
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT
    : "DEV",
  PIWIK_ID: "3722",
  ALLOWED_DOMAINS: allowed_domains,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN
    ? process.env.REACT_APP_SENTRY_DSN
    : "",
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV
    ? process.env.REACT_APP_SENTRY_ENV
    : "",
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID
    ? process.env.REACT_APP_CLIENT_ID
    : "webcast_website_test_fe",
  WEBCAST_LITE_BASE_URL: process.env.REACT_APP_WEBCAST_LITE_BASE_URL
    ? process.env.REACT_APP_WEBCAST_LITE_BASE_URL
    : "https://localhost:3000",
};

const prod: IAppConfig = {
  api: {
    ENDPOINT: process.env.REACT_APP_API_ENDPOINT
      ? process.env.REACT_APP_API_ENDPOINT
      : "",
  },
  IS_DEV_INSTALL: process.env.REACT_APP_IS_DEV_INSTALL === "true",
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT
    : "DEV",
  PIWIK_ID: process.env.REACT_APP_PIWIK_ID
    ? process.env.REACT_APP_PIWIK_ID
    : "0",
  ALLOWED_DOMAINS: allowed_domains,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN
    ? process.env.REACT_APP_SENTRY_DSN
    : "",
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV
    ? process.env.REACT_APP_SENTRY_ENV
    : "",
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID
    ? process.env.REACT_APP_CLIENT_ID
    : "",
  WEBCAST_LITE_BASE_URL: process.env.REACT_APP_WEBCAST_LITE_BASE_URL
    ? process.env.REACT_APP_WEBCAST_LITE_BASE_URL
    : "https://localhost:3000",
};

const testConfig: IAppConfig = {
  api: {
    ENDPOINT: "http://localhost",
  },
  IS_DEV_INSTALL: true,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT
    : "DEV",
  PIWIK_ID: "0",
  ALLOWED_DOMAINS: allowed_domains,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN
    ? process.env.REACT_APP_SENTRY_DSN
    : "",
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV
    ? process.env.REACT_APP_SENTRY_ENV
    : "",
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID
    ? process.env.REACT_APP_CLIENT_ID
    : "webcast_website_test_fe",
  WEBCAST_LITE_BASE_URL: process.env.REACT_APP_WEBCAST_LITE_BASE_URL
    ? process.env.REACT_APP_WEBCAST_LITE_BASE_URL
    : "https://localhost:3000",
};

let tempConfig = testConfig;

if (process.env.NODE_ENV === "production") {
  tempConfig = prod;
}
if (process.env.NODE_ENV === "development") {
  tempConfig = dev;
}

const appConfig = tempConfig;

export default appConfig;
