import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { chunk } from "lodash";
import { DateTime } from "luxon";
import {
  Grid,
  Item,
  Button,
  Icon,
  Segment,
  Dimmer,
  Loader,
  Message,
  MessageHeader,
} from "semantic-ui-react";
import { useCdsRecords } from "hooks/cds-records/use-cds-records";
import { ICdsRecord } from "types/cds-record";

interface IProps {
  columns: number;
}

export default function CdsRecords({ columns }: Readonly<IProps>) {
  const { data, isLoading, error, isError } = useCdsRecords();
  const [rows, setRows] = useState<any>([]);

  useEffect(() => {
    if (data && data.results.length > 0) {
      setRows(chunk(data.results, columns));
    }
  }, [columns, setRows, data]);

  const getWebcastColum = (record: ICdsRecord, index: number) => (
    <Grid.Column key={`cds-record-${index}`}>
      <Item.Group>
        <Item>
          <Item.Content>
            <Item.Header as="h3" style={{ fontSize: "1.2em" }}>
              {record.title}
            </Item.Header>
            {record.speakers && <Item.Meta>by {record.speakers}</Item.Meta>}
            <Item.Description>
              <Icon name="calendar" />
              {DateTime.fromISO(record.published_date).toFormat("dd-MM-yyyy")}
            </Item.Description>
            <Item.Extra className="accExtra">
              <Button
                primary
                size="small"
                floated="right"
                className="cernPrimary"
                href={record.link}
              >
                View on CDS
                <Icon name="chevron right" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </Grid.Column>
  );

  if (isLoading) {
    return (
      <Segment basic textAlign="center">
        <Dimmer active inverted>
          <Loader>Loading...</Loader>
        </Dimmer>
      </Segment>
    );
  }

  if (isError) {
    return (
      <Message negative>
        <MessageHeader>
          Unable to fetch the CDS records (Error {error.response?.status})
        </MessageHeader>
        <p>
          We encountered an error while retrieving the CDS records from the API.
        </p>
      </Message>
    );
  }

  return (
    <>
      {rows && rows.length > 0 && (
        <Grid columns={3} divided>
          {rows.map((cols: ICdsRecord[], index: number) => (
            <Grid.Row key={`recent-col-${index.toString()}`}>
              {cols?.map((record: ICdsRecord, index2: number) =>
                getWebcastColum(record, index2),
              )}
            </Grid.Row>
          ))}
        </Grid>
      )}
      {rows.length === 0 && (
        <Segment basic textAlign="center">
          There are no cds records.
        </Segment>
      )}
    </>
  );
}
