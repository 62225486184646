// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
    --main-fg-color: white;
    --main-bg-color: rgba(0,0,0,0.8);
    --main-bg-color-hover: rgb(0,0,0);
    --secondary-bg-color: rgb(32,32,32);
    --secondary-bg-color-hover: rgba(32,32,32,0.8);
    --highlight-bg-color: #A00;
    --highlight-bg-color-hover: red;
    --main-bg-gradient: linear-gradient(0deg, rgba(32,32,32,1) 0%, rgba(32,32,32,0.49531687675070024) 72%, rgba(32,32,32,0.08355217086834732) 100%);
    --main-border-color: rgba(125,125,125,0.4);
    --video-container-background-color: #e4e4e4;
    --base-video-rect-background-color: #8a8a8a;
    --main-outline-color: var(--highlight-bg-color-hover);
}
`, "",{"version":3,"sources":["webpack://./src/css/colors.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,gCAAgC;IAChC,iCAAiC;IACjC,mCAAmC;IACnC,8CAA8C;IAC9C,0BAA0B;IAC1B,+BAA+B;IAC/B,+IAA+I;IAC/I,0CAA0C;IAC1C,2CAA2C;IAC3C,2CAA2C;IAC3C,qDAAqD;AACzD","sourcesContent":["\n:root {\n    --main-fg-color: white;\n    --main-bg-color: rgba(0,0,0,0.8);\n    --main-bg-color-hover: rgb(0,0,0);\n    --secondary-bg-color: rgb(32,32,32);\n    --secondary-bg-color-hover: rgba(32,32,32,0.8);\n    --highlight-bg-color: #A00;\n    --highlight-bg-color-hover: red;\n    --main-bg-gradient: linear-gradient(0deg, rgba(32,32,32,1) 0%, rgba(32,32,32,0.49531687675070024) 72%, rgba(32,32,32,0.08355217086834732) 100%);\n    --main-border-color: rgba(125,125,125,0.4);\n    --video-container-background-color: #e4e4e4;\n    --base-video-rect-background-color: #8a8a8a;\n    --main-outline-color: var(--highlight-bg-color-hover);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
