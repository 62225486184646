import Keycloak from "keycloak-js";
import config from "config";

const keycloak = new Keycloak({
  url: "https://auth.cern.ch/auth",
  realm: "cern",
  clientId: config.CLIENT_ID,
});

export default keycloak;
