import React from "react";
import { Menu, Container } from "semantic-ui-react";
import { Media } from "app-media";
import AuthService from "services/auth-service";

export default function TopMenu() {
  const loginUser = () => {
    AuthService.doLogin({
      redirectUri: encodeURI(window.location.href),
    });
  };

  const logoutUser = () => {
    console.log(window.location.origin);
    AuthService.doLogout({
      redirectUri: encodeURI(window.location.href),
    });
  };

  if (AuthService.isLoggedIn()) {
    return (
      <Menu inverted fixed="top" style={{ display: "block" }}>
        <Container fluid>
          <Menu.Item
            name="CERN"
            as="a"
            href="https://home.cern"
            title="CERN Home"
          />
          <Menu.Item
            as={Media}
            greaterThanOrEqual="tablet"
            name="Accelerating Science"
          />
          <Menu.Menu position="right">
            <Menu.Item name="loggedin">
              Logged in as: {AuthService.getFirstName()}{" "}
              {AuthService.getLastName()} ({AuthService.getUsername()})
            </Menu.Item>
            <Menu.Item name="logout" onClick={logoutUser}>
              Logout
            </Menu.Item>
            <Menu.Item
              as="a"
              href="https://cern.ch/directory"
              title="CERN Directory"
            >
              Directory
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }

  return (
    <Menu inverted fixed="top" style={{ display: "block" }}>
      <Container fluid>
        <Menu.Item
          name="CERN"
          as="a"
          href="https://home.cern"
          title="CERN Home"
        />
        <Menu.Item name="Accelerating Science" />
        <Menu.Menu position="right">
          <Menu.Item name="signup" onClick={loginUser}>
            Login
          </Menu.Item>
        </Menu.Menu>
        <Menu.Item
          as="a"
          href="https://cern.ch/directory"
          title="CERN Directory"
        >
          Directory
        </Menu.Item>
      </Container>
    </Menu>
  );
}
